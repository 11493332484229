import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './DocumentPage.module.css';
import Footer from './components/Footer';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import UpButton from './components/UpButton';

const TariffsPage = () => {
    useEffect(() => {
        document.title = "Список тарифов и порядок совершения платежей на Сервисе «Botique»";
    }, []);

    const contHref = useRef();

    return (
        <>
            <Header />
            <NavigationMenu />
            <div className={styles.licensePage} ref={contHref}>
                <UpButton href={contHref} />

                <h1 className={styles.centeredHeader}>
                    Список тарифов и порядок совершения платежей на Сервисе «Botique»
                </h1>

                <p className={`${styles.paragraph} ${styles.addPadding}`}>
                    Российская Федерация, город Самара
                </p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>
                    Дата размещения: 16 марта 2025 года
                </p>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>
                    Дата вступления в силу: 16 марта 2025 года
                </p>

                <h1 className={styles.centeredHeader}>Термины и определения:</h1>
                <p className={`${styles.paragraph} ${styles.addPadding}`}>
                    В рамках настоящего Договора Стороны договорились о единообразном толковании
                    следующих терминов и понятий:
                </p>
                <ul className={styles.paragraph}>
                    <li>
                        <strong className={styles.boldText}>Использование Сервиса</strong> – действия Продавца с Сервисом,
                        заключающиеся в направлении запросов на изменение внешнего вида Магазина,
                        наполнение и редактирование Контента Магазина, активации функциональности Сервиса
                        в части получения и обработки заказов, формирования доступной аналитики,
                        запуска рассылок.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Продавец</strong> – юридическое лицо и (или)
                        индивидуальный предприниматель, действующие в лице своих уполномоченных представителей,
                        принявшие оферту на заключение Договора в порядке, предусмотренном в настоящем документе.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Пользователь</strong> – полностью дееспособное физическое лицо,
                        использующее Магазин Продавца для приобретения товаров и/или услуг Продавца.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Сервис «Botique»/Сервис</strong> – программное обеспечение «Botique»,
                        размещённое на сайте в сети Интернет по адресу{" "}
                        <Link to="https://thebotique.ru">https://thebotique.ru</Link>, предназначенное
                        для создания и управления Магазинами в мессенджере Telegram.
                    </li>
                    <li>
                        Под Сервисом также понимаются все его обновления и новые версии, которые Лицензиар может,
                        но не обязан, предоставлять Продавцу.
                    </li>
                    <li>
                        <strong className={styles.boldText}>
                            Сервис зарегистрирован как программа для ЭВМ «Автоматизированная платформа
                            для создания интернет-магазинов «Botique»» в Государственном реестре программ
                            для ЭВМ (Свидетельство № 2024661306 от «16» мая 2024 г.).
                        </strong>
                    </li>
                    <li>
                        <strong className={styles.boldText}>Стороны</strong> – Лицензиар и Продавец, упоминаемые совместно.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Магазин</strong> – средство обмена электронными сообщениями (чат)
                        в мессенджере Telegram, реализованное как веб-приложение, создаваемое Продавцом
                        в автоматизированном режиме посредством использования Сервиса в целях осуществления
                        Продавцом продажи товаров и/или оказания услуг покупателям.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Тариф</strong> – стоимость лицензионного вознаграждения
                        и определённого набора функций в рамках функциональности Сервиса, доступный Продавцу
                        после принятия Соглашения и подключения выбранного Продавцом Тарифа.
                    </li>
                    <li>
                        <strong className={styles.boldText}>Контент</strong> – фотографии, видео и текстовые описания
                        товаров и услуг, товарные знаки, логотипы и иные средства индивидуализации Продавца.
                    </li>
                </ul>

                <h1 className={styles.centeredHeader}>1. Описание, стоимость и сроки действия Тарифа</h1>
                <ul className={styles.paragraph}>
                    <p>
                        1.1. Сервис «Botique» предоставляет Продавцу возможность самостоятельно настроить конфигурацию Тарифа. Итоговая месячная стоимость рассчитывается по формуле:
                    </p>
                    <p className={styles.centeredParagraph}>
                        <strong>
                            (99 + (1 × количество товаров) + стоимость опций) × количество магазинов
                        </strong>
                    </p>
                    <p>
                        При этом итоговая сумма округляется в большую сторону до целого рубля.
                    </p>
                    <p>
                        1.2. Дополнительные опции, которые Продавец может выбрать для расширения функциональности, включают:
                    </p>
                    <ul>
                        <li>«Аналитика» – 100 руб. в месяц;</li>
                        <li>«Маркетинговые рассылки» – 1000 руб. в месяц;</li>
                        <li>«Интеграция CRM» – 1000 руб. в месяц;</li>
                        <li>«Премиум поддержка» – 2500 руб. в месяц;</li>
                        <li>«Подключение онлайн-оплаты» – 300 руб. в месяц;</li>
                        <li>«Интеграция с сервисом доставки Boxberry» – 250 руб. в месяц;</li>
                        <li>«Рекомендательная система» – 200 руб. в месяц;</li>
                        <li>«Отключение корзины» – 450 руб. в месяц;</li>
                        <li>«Группы товаров» – 500 руб. в месяц.</li>
                        <li>«Отключение брендинга сервиса «Botique»» – 50 руб. в месяц.</li>
                    </ul>
                    <p>
                        1.3. Продавец может выбрать срок предоплаты – 1, 3, 6 или 12 месяцев. При оплате за:
                    </p>
                    <ul>
                        <li>3 месяца – применяется скидка 5%;</li>
                        <li>6 месяцев – применяется скидка 10%;</li>
                        <li>12 месяцев – применяется скидка 20%.</li>
                    </ul>
                    <p>
                        Скидка применяется к сумме, рассчитанной по формуле, за выбранный период.
                    </p>
                    <p>
                        1.4. Оплата осуществляется через интерактивную форму на сайте, после чего открывается платежная форма для онлайн-оплаты.
                    </p>
                    <p>
                        1.5. В случае изменения конфигурации тарифа (например, изменения набора опций) в период его действия производится перерасчёт стоимости. Если новая конфигурация тарифа дороже, у Продавца запрашивается доплата в размере разницы между новой и активной стоимостью. При понижении конфигурации переплата не возвращается.
                    </p>
                    <p>
                        1.6. Срок действия доступа к Сервису соответствует выбранному и оплаченному периоду (1, 3, 6 или 12 месяцев). По окончании оплаченного периода Продавец может продлить Тариф на новых или аналогичных условиях.
                    </p>
                    <p>
                        1.7. Для подключения выбранного Тарифа (и, при необходимости, дополнительных опций) Продавцу необходимо отправить Заявку по форме, определенной в Лицензионном договоре Сервиса, размещённом по адресу:{" "}
                        <Link to="https://docs.thebotique.ru/license-agreement">
                            https://docs.thebotique.ru/license-agreement
                        </Link>{" "}
                        на электронную почту contact@thebotique.ru.
                    </p>
                    <p>
                        1.8. На основании полученной Заявки Продавцу на электронную почту, указанную в заявке, отправляется счёт на оплату выбранного Тарифа (и опций, если они были выбраны).
                    </p>
                    <p>
                        1.9. Оплата производится Продавцом по реквизитам, указанным в счёте. Расчёты осуществляются в российских рублях; исполнением обязательств считается дата зачисления денежных средств на корреспондентский счёт Лицензиара.
                    </p>
                    <p>
                        1.10. В течение 5 (пяти) рабочих дней с даты предоставления Продавцу фактического доступа к Сервису на электронную почту, указанную в Заявлении, отправляется односторонний Акт о предоставлении доступа к Сервису.
                    </p>

                    <p className={styles.centeredParagraph}>
                        <strong>1.11. Автопродление для Продавца-самозанятого гражданина</strong>
                    </p>
                    <p>
                        1.11.1. Для Продавца – самозанятого гражданина подключение и оплата выбранного Тарифа осуществляется на условиях регулярного внесения платы с автоматическим продлением доступа. Оплата для самозанятых граждан производится через интерактивную форму на сайте, открывающую платежную форму для онлайн-оплаты.
                    </p>
                    <p>
                        1.11.2. После получения информации о факте оплаты Продавцу отправляется электронный кассовый чек на указанный адрес электронной почты.
                    </p>
                    <p>
                        1.11.3. Продавцу-самозанятому гражданину не позднее, чем за 1 (один) день до Автопродления, на указанную в Сервисе электронную почту приходит уведомление о предстоящем списании.
                    </p>
                    <p>
                        1.11.4. При отказе от Автопродления, Продавцу не возвращаются уже уплаченные денежные средства, при этом Тариф продолжает действовать на оплаченный период, после чего Автопродление отменяется.
                    </p>

                    <p className={styles.centeredParagraph}>
                        <strong>1.12. Отказ от использования Сервиса</strong>
                    </p>
                    <p>
                        1.12.1. Продавец вправе в любой момент отказаться от использования Сервиса или приостановить его использование. При этом Продавец соглашается с тем, что действие Тарифа не прекращается в течение оплаченного периода, в течение которого предоставленные права не использовались, и ранее произведенные оплаты не подлежат возврату.
                    </p>
                </ul>

                <p className={styles.centeredParagraph}>
                    По всем вопросам, возникающим в связи с Тарифами и порядком совершения платежей, следует обращаться по электронной почте contact@thebotique.ru.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default TariffsPage;
